import React from "react";
import Instructions from "../components/Instructions";
import Editor from "../components/Editor";
import ApkListing from "../components/ApkListing";

class InputTool extends React.Component {
  render() {
    return (
      <div id="main">
        <Instructions />
        <div className="background">
          <Editor />
        </div>
        <ApkListing />
      </div>
    );
  }
}

export default InputTool;
