import axios from "axios";
import host from "../config/hosts";

const CancelToken = axios.CancelToken;
let source = CancelToken.source();
export const fetchSuggestions = async (word, code) => {
  // if (source && word.length % 3 !== 0) {
  if (source && word.length !== 3 && (word.length + 12) % 3 !== 0) {
    source.cancel("Operation canceled due to new request.");
  }

  // save the new request for cancellation
  source = axios.CancelToken.source();

  let response;
  try {
    response = await axios.get(`${host}/v1/transliterate/${word}/${code}`, {
      cancelToken: source.token,
    });
  } catch (err) {
    console.log(err);
  }

  return response;
};

export const getLatestAppVersion = async () => {
  try {
    const apkHost = "https://api.bobbleapp.me";
    const response = await axios.get(
      apkHost + "/v4/download/latestAppVersionDetails"
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getTrackableDownloadLink = (url) => {
  const stateParams = parseParams();
  let utmCampaign = stateParams.utmCampaign ? stateParams.utmCampaign : "";
  let utmMedium = stateParams.utmMedium ? stateParams.utmMedium : "";
  let utmSource = stateParams.utmSource ? stateParams.utmSource : "";
  let utmContent = stateParams.utmContent ? stateParams.utmContent : "";
  let utmTerm = stateParams.utmTerm ? stateParams.utmTerm : "";
  if (
    utmCampaign.length === 0 &&
    utmMedium.length === 0 &&
    utmSource.length === 0 &&
    utmTerm.length === 0 &&
    utmContent.length === 0
  ) {
    utmCampaign = "input-tool-playstore";
    utmSource = "website";
    utmMedium = "website";
  }
  url = url.replace(/__UTM_CAMPAIGN__/g, encodeURIComponent(utmCampaign));
  url = url.replace(/__UTM_MEDIUM__/g, encodeURIComponent(utmMedium));
  url = url.replace(/__UTM_SOURCE__/g, encodeURIComponent(utmSource));
  url = url.replace(/__UTM_CONTENT__/g, encodeURIComponent(utmContent));
  url = url.replace(/__UTM_TERM__/g, encodeURIComponent(utmTerm));
  return url;
};

const parseParams = () => {
  let urlParams;

  let match,
    pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s) {
      return decodeURIComponent(s.replace(pl, " "));
    },
    query = window.location.search.substring(1);

  urlParams = {};
  while ((match = search.exec(query)))
    urlParams[decode(match[1])] = decode(match[2]);

  return urlParams;
};
