import React from "react";
import MakeInIndiaLogo from "../assets/images/make-in-india-logo.png"

class Instructions extends React.Component {
  render() {
    return (
      <div
        className="instructions d-block mx-auto"
        id="content"
        style={{ maxWidth: "978px" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Try Bobble Input Tools online</h2>
            <p>
              Bobble Input Tools makes it easy to type in the language you choose,
              anywhere on the web.
            </p>
            <p>
              To try it out, choose your language and input tool below and begin
              typing.
            </p>
          </div>
          <div className="d-none d-lg-block">
            <img src={MakeInIndiaLogo} alt="Make In India" style={{ width: "145px", height: "78px" }} />
          </div>
        </div>
      </div>
    );
  }
}

export default Instructions;
