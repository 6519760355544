import React from "react";

class Header extends React.Component {
  render() {
    return (
      <div className="header" id="header" role="banner">
        <div className="section-1">
          <h1>
            <a href="https://bobble.ai/home" target="_blank" rel="noreferrer">
              <img alt="Bobble AI" src="logo.png" />
            </a>
            <img
              className="ml-2"
              alt="Input Tool"
              src="/images/header/input-tool.png"
            />
          </h1>
        </div>
      </div>
    );
  }
}

export default Header;
