import React from "react";

export default class PageNotFound extends React.Component {
  render() {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <h1 style={{ fontSize: "20px", opacity: "0.85" }}>
          404 | This page could not be found.
        </h1>
      </div>
    );
  }
}
