export const languages = [
  {
    name: "Hindi",
    code: "hi",
    isTranslitarationSupported: true,
    path: "/englishtohindi",
    placeholder: "Type in Here - यहां टाइप करें",
    title: "Free English to Hindi Transliteration Tool | Type in Hindi (हिन्दी में टाइप करें) in 2021",
    description: "Type in Hindi like a pro using Free English to Hindi Translation Bobble Input tool. Instant Online Hindi Converter."
  },
  {
    name: "Tamil (India)",
    code: "ta",
    isTranslitarationSupported: true,
    path: "/englishtotamil",
    placeholder: "",
    title: " Free English to Tamil Transliteration Tool | Type in Tamil (டிபே இன் தமிழ்) in 2021",
    description: "Type in Tamil like a pro using Free English to Tamil Translation Bobble Input tool. Instant Online Tamil Converter."
  },
  {
    name: "Malayalam",
    code: "ml",
    isTranslitarationSupported: true,
    path: "/englishtomalayalam",
    placeholder: "Type in Here - ഇവിടെ ടൈപ്പുചെയ്യുക",
    title: "Free English to Malayalam Transliteration Tool | Type in Malayalam (ടൈപ്പ് ഇൻ മലയാളം ) in 2021",
    description: "Type in Malayalam like a pro using Free English to Malayalam Translation Bobble Input tool. Instant Online Malayalam Converter."
  },
  {
    name: "Telugu",
    code: "te",
    isTranslitarationSupported: true,
    path: "/englishtotelugu",
    placeholder: "",
    title: "Free English to Telugu Transliteration Tool | Type in Telugu (టైపు ఇన్ తెలుగు  ) in 2021",
    description: "Type in Telugu like a pro using Free English to Telugu Translation Bobble Input tool. Instant Online Telugu Converter."
  },
  {
    name: "Marathi",
    code: "mr",
    isTranslitarationSupported: true,
    path: "/englishtomarathi",
    placeholder: "Type in Here - इथे टाइप करा",
    title: "Free English to Marathi Transliteration Tool | Type in Marathi (मराठी टाइप करा ) in 2021",
    description: "Type in Marathi like a pro using Free English to Marathi Translation Bobble Input tool. Instant Online Marathi Converter."
  },
  {
    name: "Gujarati",
    code: "gu",
    isTranslitarationSupported: true,
    path: "/englishtogujarati",
    placeholder: "",
    title: "Free English to Gujarati Transliteration Tool | Type in Gujarati (ગુજરાતીમાં લખો) in 2021",
    description: "Type in Gujarati like a pro using Free English to Gujarati Translation Bobble Input tool. Instant Online Gujarati Converter."
  },
  {
    name: "Bangla (India)",
    code: "bn",
    isTranslitarationSupported: true,
    path: "/englishtobangla",
    placeholder: "Type in Here - এখানে টাইপ করুন",
    title: "Free English to Bangla (India)Transliteration Tool | Type in Bangla (India)(বাংলায় টাইপ করুন in 2021",
    description: "Type in Bangla (India) like a pro using Free English to Bangla Translation Bobble Input tool. Instant Online Bangla Converter."
  },
  {
    name: "Punjabi, Gurmukhi",
    code: "pa",
    isTranslitarationSupported: true,
    path: "/englishtopunjabi",
    placeholder: "",
    title: "Free English to Punjabi (Gurmukhi)Transliteration Tool | Type in Punjabi (Gurmukhi) (ਟੀਪੀ ਇਨ ਪੰਜਾਬੀ ) in 2021",
    description: "Type in Punjabi (Gurmukhi) like a pro using Free English to Punjabi Translation Bobble Input tool. Instant Online Punjabi Converter."
  },
  {
    name: "Kannada",
    code: "kn",
    isTranslitarationSupported: true,
    path: "/englishtokannada",
    placeholder: "",
    title: "Free English to Kannada Transliteration Tool | Type in Kannada (ಕನ್ನಡದಲ್ಲಿ ಟೈಪ್ ಮಾಡಿ) in 2021",
    description: "Type in Kannada like a pro using Free English to KannadaTranslation Bobble Input tool. Instant Online Kannada Converter."
  },
  {
    name: "Odia",
    code: "or",
    isTranslitarationSupported: true,
    path: "/englishtoodia",
    placeholder: "",
    title: "Free English to OdiaTransliteration Tool | Type in Odia(ଓଡିଆରେ ଟାଇପ୍ କରନ୍ତୁ )in 2021",
    description: "Type in Odia like a pro using Free English to Odia Translation Bobble Input tool. Instant Online OdiaConverter."
  },
  {
    name: "Sanskrit",
    code: "sa",
    isTranslitarationSupported: true,
    path: "/englishtosanskrit",
    placeholder: "",
    title: "Free English to Sanskrit Transliteration Tool | Type in Sanskrit (त्य्पे इन् संस्कृत) in 2021",
    description: "Type in Sanskrit like a pro using Free English to Sanskrit Translation Bobble Input tool. Instant Online Sanskrit Converter."
  },
  {
    name: "Assamese",
    code: "as",
    isTranslitarationSupported: true,
    path: "/englishtoassamese",
    placeholder: "",
    title: "Free English to Assamese Transliteration Tool | Type in Assamese (টিপি ইন আচমেছে ) in 2021",
    description: "Type in Assamese like a pro using Free English to Assamese Translation Bobble Input tool. Instant Online Assamese Converter."
  },
  {
    name: "Ahirani",
    code: "ahr",
    isTranslitarationSupported: true,
    path: "/englishtoahirani",
    placeholder: "",
    title: "Free English to Ahirani Transliteration Tool | Type in Ahirani (त्यपे इन अहिराणी ) in 2021",
    description: "Type in Ahirani like a pro using Free English to Ahirani Translation Bobble Input tool. Instant Online Ahirani Converter."
  },
  {
    name: "Awadhi",
    code: "awa",
    isTranslitarationSupported: true,
    path: "/englishtoawadhi",
    placeholder: "",
    title: "Free English to Awadhi Transliteration Tool | Type in Awadhi (त्यपे इं अवधी ) in 2021",
    description: "Type in Awadhi like a pro using Free English to Awadhi Translation Bobble Input tool. Instant Online Awadhi Converter."
  },
  {
    name: "Bagheli",
    code: "bfy",
    isTranslitarationSupported: true,
    path: "/englishtobagheli",
    placeholder: "",
    title: "Free English to Bagheli Transliteration Tool | Type in Bagheli (त्यप इन बघेली) in 2021",
    description: "Type in Bagheli like a pro using Free English to Bagheli Translation Bobble Input tool. Instant Online Bagheli Converter."
  },
  {
    name: "Bagri (India)",
    code: "bgq_dev",
    isTranslitarationSupported: true,
    path: "/englishtobagri-india",
    placeholder: "",
    title: "Free English to Bagri (India) Transliteration Tool | Type in Bagri (त्यपे इं बागरी ) in 202",
    description: "Type in Bagri (India) like a pro using Free English to Bagri Translation Bobble Input tool. Instant Online Bagri Converter."
  },
  {
    name: "Bagri (Pakistan)",
    code: "bgq_arab",
    isTranslitarationSupported: true,
    path: "/englishtobagri-pakistan",
    placeholder: "",
    title: "Free English to Bagri (Pakistan) Transliteration Tool | Type in Bagri (ڪتائپڙي ن بگري) in 2021",
    description: "Type in Bagri like a pro using Free English to Bagri Translation Bobble Input tool. Instant Online Bagri Converter."
  },
  {
    name: "Nepali (India)",
    code: "ne",
    isTranslitarationSupported: true,
    path: "/englishtonepali",
    placeholder: "",
    title: "Free English to Nepali (India) Transliteration Tool | Type in Nepali (त्य्पे इन नेपाली ) in 2021",
    description: "Type in Nepali (India) like a pro using Free English to Nepali Translation Bobble Input tool. Instant Online Nepali Converter."
  },
  {
    name: "Sinhala",
    code: "si",
    isTranslitarationSupported: true,
    path: "/englishtosinhala",
    placeholder: "",
    title: "Free English to Sinhala Transliteration Tool | Type in Sinhala (සිංහලෙන් ටයිප් කරන්න ) in 2021",
    description: "Type in Sinhala like a pro using Free English to Sinhala Translation Bobble Input tool. Instant Online Sinhala Converter."
  },
  {
    name: "Dogri, Devanagari",
    code: "doi",
    isTranslitarationSupported: true,
    path: "/englishtodogri-devanagari",
    placeholder: "",
    title: "Free English to Dogri (Devanagari) Transliteration Tool | Type in Dogri (टाइपे इन दोग्री ) in 2021",
    description: "Type in Dogri (Devanagari) like a pro using Free English to Dogri Translation Bobble Input tool. Instant Online Dogri Converter."
  },
  {
    name: "Tulu",
    code: "tcy",
    isTranslitarationSupported: true,
    path: "/englishtotulu",
    placeholder: "",
    title: "Free English to Tulu Transliteration Tool | Type in Tulu (ತಯ್ಪೆ ಇನ್ ತುಳು) in 2021",
    description: "Type in Tulu like a pro using Free English to Tulu Translation Bobble Input tool. Instant Online Tulu Converter."
  },
  {
    name: "Bhili, Devanagari",
    code: "bhb_dev",
    isTranslitarationSupported: true,
    path: "/englishtobhili-devanagri",
    placeholder: "",
    title: "Free English to Bhili (Devanagari) Transliteration Tool | Type in Bhili (त्यप इन भिली ) in 2021",
    description: "Type in Bhili (Devanagari) like a pro using Free English to Bhili Translation Bobble Input tool. Instant Online Bhili Converter."
  },
  {
    name: "Bhili, Gujarati",
    code: "bhb_gujr",
    isTranslitarationSupported: true,
    path: "/englishtobhili-gujarati",
    placeholder: "",
    title: "Free English to Bhili (Gujarati)Transliteration Tool | Type in Bhili (ત્યપે ઇન ભીલી ) in 2021",
    description: "Type in Bhili (Gujarati) like a pro using Free English to Bhili Translation Bobble Input tool. Instant Online Bhili Converter."
  },
  {
    name: "Bhojpuri",
    code: "bho_dev",
    isTranslitarationSupported: true,
    path: "/englishtobhojpuri",
    placeholder: "",
    title: "Free English to Bhojpuri Transliteration Tool | Type in Bhojpuri (तयपे इन मालवी) in 2021",
    description: "Type in Bhojpuri like a pro using Free English to Bhojpuri Translation Bobble Input tool. Instant Online Bhojpuri Converter."
  },
  {
    name: "Urdu (India)",
    code: "ur",
    isTranslitarationSupported: true,
    path: "/englishtourdu-india",
    placeholder: "",
    title: "Free English to Urdu(India)Transliteration Tool | Type in Urdu(تیپے ان اردو ) in 2021",
    description: "Type in Urdu like a pro using Free English to UrduTranslation Bobble Input tool. Instant Online Urdu Converter."
  },
  {
    name: "Bishnupriya",
    code: "bpy_beng",
    isTranslitarationSupported: true,
    path: "/englishtobishnupriya",
    placeholder: "",
    title: " Free English to Bishnupriya Transliteration Tool | Type in Bishnupriya (ত্যপে ইন বিষ্ণুপ্রি্যা ) in 2021",
    description: "Type in Bishnupriya like a pro using Free English to Bishnupriya Translation Bobble Input tool. Instant Online Bishnupriya Converter."
  },
  {
    name: "Bodo, Bengali",
    code: "brx_beng",
    isTranslitarationSupported: true,
    path: "/englishtobodo-bengali",
    placeholder: "",
    title: "Free English to Bodo(Bengali) Transliteration Tool | Type in Bodo(তপে ইং বদ ) in 2021",
    description: "Type in Bodo(Bengali) like a pro using Free English to BodoTranslation Bobble Input tool. Instant Online Bodo Converter."
  },
  {
    name: "Bodo, Devanagari",
    code: "brx_dev",
    isTranslitarationSupported: true,
    path: "/englishtobodo-devanagari",
    placeholder: "",
    title: "Free English to Bodo(Devanagari)Transliteration Tool | Type in Bodo(त्यपे इन बोडो) in 2021",
    description: "Type in Bodo like a pro using Free English to Bodo Translation Bobble Input tool. Instant Online Bodo Converter."
  },
  {
    name: "Brahui",
    code: "brh_urdu",
    isTranslitarationSupported: true,
    path: "/englishtobrahui",
    placeholder: "",
    title: "Free English to Brahui Transliteration Tool | Type in Brahui (5629تی٤٤ 1464 10راہوئ١٣١٦٩) in 2021",
    description: "Type in Brahui like a pro using Free English to Brahui Translation Bobble Input tool. Instant Online Brahui Converter."
  },
  {
    name: "Bundeli",
    code: "bns_dev",
    isTranslitarationSupported: true,
    path: "/englishtobundeli",
    placeholder: "",
    title: "Free English to Bundeli Transliteration Tool | Type in Bundeli (त्यपे इन बुन्देली ) in 2021",
    description: "Type in Bundeli like a pro using Free English to Bundeli Translation Bobble Input tool. Instant Online Bundeli Converter."
  },
  {
    name: "Chhattisgarhi",
    code: "hne_dev",
    isTranslitarationSupported: true,
    path: "/englishtochhattisgarhi",
    placeholder: "",
    title: "Free English to Chhattisgarhi Transliteration Tool | Type in Chhattisgarhi (छत्ती‍सगढ़ी में त्यपे करे ) in 2021",
    description: "Type in Chhattisgarhi like a pro using Free English to Chhattisgarhi Translation Bobble Input tool. Instant Online Chhattisgarhi Converter"
  },
  {
    name: "Chittagonian",
    code: "ctg_beng",
    isTranslitarationSupported: true,
    path: "/englishtochittagonian",
    placeholder: "",
    title: "Free English to Chittagonian Transliteration Tool | Type in Chittagonian (চিটাগনিান্ত মেইন টাইপ কা) in 2021",
    description: "Type in Chittagonian like a pro using Free English to Chittagonian Translation Bobble Input tool. Instant Online Chittagonian Converter."
  },
  {
    name: "Dhundhari",
    code: "dhd_hindi",
    isTranslitarationSupported: true,
    path: "/englishtodhundhari",
    placeholder: "",
    title: "Free English to Dhundhari Transliteration Tool | Type in Dhundhari (ढूंढरी में त्यपी कारे ) in 2021",
    description: "Type in Dhundhari like a pro using Free English to Dhundhari Translation Bobble Input tool. Instant Online Dhundhari Converter."
  },
  {
    name: "Dogri, Arabic",
    code: "doi_arab",
    isTranslitarationSupported: true,
    path: "/englishtodogri-arabic",
    placeholder: "",
    title: "Free English to Dogri, Arabic Transliteration Tool | Type in Dogri, Arabic (کرائی٣٣٤٨٣٢) in 2021",
    description: "Type in Dogri like a pro using Free English to Dogri Translation Bobble Input tool. Instant Online Dogri Converter."
  },
  {
    name: "Garhwali",
    code: "gbm_dev",
    isTranslitarationSupported: true,
    path: "/englishtogarhwali",
    placeholder: "",
    title: "Free English to Garhwali Transliteration Tool | Type in Garhwali (हिन्दी में टाइप करें) in 2021",
    description: "Type in Garhwali like a pro using Free English to Garhwali Translation Bobble Input tool. Instant Online Garhwali Converter."
  },
  {
    name: "Garo",
    code: "grt_beng",
    isTranslitarationSupported: true,
    path: "/englishtogaro",
    placeholder: "",
    title: "Free English to Garo Transliteration Tool | Type in Garo (গারো মেইন তয়পে করেইন) in 2021",
    description: "Type in Garo like a pro using Free English to Garo Translation Bobble Input tool. Instant Online Garo Converter."
  },
  {
    name: "Godwari",
    code: "gdx_dev",
    isTranslitarationSupported: true,
    path: "/englishtogodwari",
    placeholder: "",
    title: "Free English to Godwari Transliteration Tool | Type in Godwari (गोद्वारी में त्यपे करें ) in 2021",
    description: "Type in Godwari like a pro using Free English to Godwari Translation Bobble Input tool. Instant Online Godwari Converter."
  },
  {
    name: "Gujari (India)",
    code: "gju_dev",
    isTranslitarationSupported: true,
    path: "/englishtogujari-india",
    placeholder: "",
    title: "Free English to Gujari (India) Transliteration Tool | Type in Gujari (India) (गुजारी में त्यपे करें ) in 2021",
    description: "Type in Gujari (India) like a pro using Free English to Gujari (India) Translation Bobble Input tool. Instant Online Gujari (India) Converter."
  },
  {
    name: "Gujari (Pakistan)",
    code: "gju_arab",
    isTranslitarationSupported: true,
    path: "/englishtogujari-pakistan",
    placeholder: "",
    title: "Free English to Gujari (Pakistan) Transliteration Tool | Type in Gujari (Pakistan) (گجری مین تیپے کرین ) in 2021",
    description: "Type in Gujari (Pakistan) like a pro using Free English to Gujari (Pakistan) Translation Bobble Input tool. Instant Online Gujari (Pakistan) Converter."
  },
  {
    name: "Halbi, Devanagari",
    code: "hlb_dev",
    isTranslitarationSupported: true,
    path: "/englishtohalbi-devanagri",
    placeholder: "",
    title: "Free English to Halbi, Devanagari Transliteration Tool | Type in Halbi, Devanagari (हलबी मेईन त्यपे करेईन) in 2021",
    description: "Type in Halbi, Devanagari like a pro using Free English to Halbi, Devanagari Translation Bobble Input tool. Instant Online Halbi, Devanagari Converter."
  },
  {
    name: "Halbi, Odia",
    code: "hlb_oria",
    isTranslitarationSupported: true,
    path: "/englishtohalbi-odia",
    placeholder: "",
    title: "Free English to Halbi, Odia Transliteration Tool | Type in Halbi, Odia (ହଲବୀ ମେଈନ ଟଯପେ କରେଈନ ) in 2021",
    description: "Type in Halbi, Odia like a pro using Free English to Halbi, Odia Translation Bobble Input tool. Instant Online Halbi, Odia Converter."
  },
  {
    name: "Harauti",
    code: "hoj_dev",
    isTranslitarationSupported: true,
    path: "/englishtoharauti",
    placeholder: "",
    title: "Free English to Harauti Transliteration Tool | Type in Harauti (हराउती में त्यपे करें) in 2021",
    description: "Type in Harauti like a pro using Free English to Harauti Translation Bobble Input tool. Instant Online Harauti Converter."
  },
  {
    name: "Haryanvi",
    code: "bgc_dev",
    isTranslitarationSupported: true,
    path: "/englishtoharyanvi",
    placeholder: "",
    title: "Free English to Haryanvi Transliteration Tool | Type in Haryanvi (हारयणवी में टयपे करैण) in 2021",
    description: "Type in Haryanvi like a pro using Free English to Haryanvi Translation Bobble Input tool. Instant Online Haryanvi Converter."
  },
  {
    name: "Kannauji",
    code: "bjj_dev",
    isTranslitarationSupported: true,
    path: "/englishtokannauji",
    placeholder: "",
    title: "Free English to Kannauji Transliteration Tool | Type in Kannauji (कन्नौजी में त्यपे करें) in 2021",
    description: "Type in Kannauji like a pro using Free English to Kannauji Translation Bobble Input tool. Instant Online Kannauji Converter."
  },
  {
    name: "Kashmiri, Arabic",
    code: "ks_arab",
    isTranslitarationSupported: true,
    path: "/englishtokashmiri-arabic",
    placeholder: "",
    title: "Free English to Kashmiri, Arabic Transliteration Tool | Type in Kashmiri, Arabic (کشمیری مین ٹائپے کرین ) in 2021",
    description: "Type in Kashmiri, Arabic like a pro using Free English to Kashmiri, Arabic Translation Bobble Input tool. Instant Online Kashmiri, Arabic Converter"
  },
  {
    name: "Kashmiri, Devanagari",
    code: "ks_dev",
    isTranslitarationSupported: true,
    path: "/englishtokashmiri-devanagari",
    placeholder: "",
    title: "Free English to Kashmiri, Devanagari Transliteration Tool | Type in Kashmiri, Devanagari (कश्मीरी में टिपे करें ) in 2021",
    description: "Type in Kashmiri, Devanagari like a pro using Free English to Kashmiri, Devanagari Translation Bobble Input tool. Instant Online Kashmiri, Devanagari Converter."
  },
  {
    name: "Kok Borok, Bengali",
    code: "trp_beng",
    isTranslitarationSupported: true,
    path: "/englishtokokborol-bengali",
    placeholder: "",
    title: "Free English to Kok Borok, Bengali Transliteration Tool | Type in Kok Borok, Bengali (কোক্ বোরোক্ মেইন তিপে কারিিন্) in 2021",
    description: "Type in Kok Borok, Bengali like a pro using Free English to Kok Borok, Bengali Translation Bobble Input tool. Instant Online Kok Borok, Bengali Converter."
  },
  {
    name: "Konkani, Devanagari",
    code: "kok_dev",
    isTranslitarationSupported: true,
    path: "/englishtokonkani-devanagari",
    placeholder: "",
    title: "Free English to Konkani, Devanagari Transliteration Tool | Type in Konkani, Devanagari (कोनकनी मेिन त्यपे करेिन) in 2021",
    description: "Type in Konkani, Devanagari like a pro using Free English to Konkani, Devanagari Translation Bobble Input tool. Instant Online Konkani, Devanagari Converter."
  },
  {
    name: "Konkani, Kannada",
    code: "kok_knda",
    isTranslitarationSupported: true,
    path: "/englishtokonkani-kannada",
    placeholder: "",
    title: "Free English to Konkani, Kannada Transliteration Tool | Type in Konkani, Kannada (ಕೊಂಕಣಿ ಮೆೈನ್ ಟೈಪ್ ಕರೆಯ್ನ್) in 2021",
    description: "Type in Konkani, Kannada like a pro using Free English to Konkani, Kannada Translation Bobble Input tool. Instant Online Konkani, Kannada Converter."
  },
  {
    name: "Korku",
    code: "kfq_dev",
    isTranslitarationSupported: true,
    path: "/englishtokorku",
    placeholder: "",
    title: "Free English to Korku Transliteration Tool | Type in Korku (कोरकू मैिन तयपै करैन) in 2021",
    description: "Type in Korku like a pro using Free English to Korku Translation Bobble Input tool. Instant Online Korku Converter."
  },
  {
    name: "Kumaoni",
    code: "kfy_dev",
    isTranslitarationSupported: true,
    path: "/englishtokumaoni",
    placeholder: "",
    title: "Free English to Kumaoni Transliteration Tool | Type in Kumaoni (कुमाऊंनी में त्यप करें) in 2021",
    description: "Type in Kumaoni like a pro using Free English to Kumaoni Translation Bobble Input tool. Instant Online Kumaoni Converter."
  },
  {
    name: "Kurukh, Devanagari",
    code: "kru_dev",
    isTranslitarationSupported: true,
    path: "/englishtokurukh-devanagari",
    placeholder: "",
    title: "Free English to Kurukh, Devanagari Transliteration Tool | Type in Kurukh, Devanagari (कूरुख मेइन त'पे करैिन) in 2021",
    description: "Type in Kurukh, Devanagari like a pro using Free English to Kurukh, Devanagari Translation Bobble Input tool. Instant Online Kurukh, Devanagari Converter."
  },
  {
    name: "Kurukh, Malayalam",
    code: "kru_mlym",
    isTranslitarationSupported: true,
    path: "/englishtokurukh-malayalam",
    placeholder: "",
    title: "Free English to Kurukh, Malayalam Transliteration Tool | Type in Kurukh, Malayalam (കുരുഖ് മേിൻ ടയ്പേ കരേിൻ) in 2021",
    description: "Type in Kurukh, Malayala like a pro using Free English to Kurukh, Malayala Translation Bobble Input tool. Instant Online Kurukh, Malayala Converter."
  },
  {
    name: "Lambadi, Devanagari",
    code: "lmn_dev",
    isTranslitarationSupported: true,
    path: "/englishtolambadi-devanagari",
    placeholder: "",
    title: "Free English to Lambadi, Devanagari Transliteration Tool | Type in Lambadi, Devanagari (लाम्बादी में त्यपे करें) in 2021",
    description: "Type in Lambadi, Devanagari like a pro using Free English to Lambadi, Devanagari Translation Bobble Input tool. Instant Online Lambadi, Devanagari Converter."
  },
  {
    name: "Lambadi, Telugu",
    code: "lmn_telu",
    isTranslitarationSupported: true,
    path: "/englishtolambadi-telugu",
    placeholder: "",
    title: "Free English to Lambadi, Telugu Transliteration Tool | Type in Lambadi, Telugu (లంబాడి  మెిన్ టీపే కరేిన్) in 2021",
    description: "Type in Lambadi, Telugu like a pro using Free English to Lambadi, Telugu Translation Bobble Input tool. Instant Online Lambadi, Telugu Converter."
  },
  {
    name: "Lambadi, Kannada",
    code: "lmn_knda",
    isTranslitarationSupported: true,
    path: "/englishtolambadi-kannada",
    placeholder: "",
    title: "Free English to Lambadi, Kannada Transliteration Tool | Type in Lambadi, Kannada (ಟೈಪ್ ಇನ್ ಲಂಬಾದಿ) in 2021",
    description: "Type in Lambadi, Kannada like a pro using Free English to Lambadi, Kannada Translation Bobble Input tool. Instant Online Lambadi, Kannada Converter."
  },
  {
    name: "Magahi (India)",
    code: "mag_dev",
    isTranslitarationSupported: true,
    path: "/englishtolambadi-maghi",
    placeholder: "",
    title: "Free English to Magahi(India)Transliteration Tool | Type in Magahi(त्यपे इन मगाही) in 2021",
    description: "Type in Magahi like a pro using Free English to Magahi Translation Bobble Input tool. Instant Online Magahi Converter."
  },
  {
    name: "Maithili",
    code: "mai_dev",
    isTranslitarationSupported: true,
    path: "/englishtolambadi-maithili",
    placeholder: "",
    title: "Free English to Maithili Transliteration Tool | Type in Maithili (ट्यपे इन मैथिली) in 2021",
    description: "Type in Maithili like a pro using Free English to Maithili Translation Bobble Input tool. Instant Online Maithili Converter."
  },
  {
    name: "Malvi",
    code: "mup_dev",
    isTranslitarationSupported: true,
    path: "/englishtolambadi-malvi",
    placeholder: "",
    title: "Free English to Malvi Transliteration Tool | Type in Malvi (तयपे इन मालवी) in 2021",
    description: "Type in Malvi like a pro using Free English to Malvi Translation Bobble Input tool. Instant Online Malvi Converter."
  },
  {
    name: "Mandeali",
    code: "mjl_dev",
    isTranslitarationSupported: true,
    path: "/englishtolambadi-mandeali",
    placeholder: "",
    title: "Free English to Mandeali Transliteration Tool | Type in Mandeali (त्यपै इं मऩाळि ) in 2021",
    description: "Type in Mandeali like a pro using Free English to Mandeali Translation Bobble Input tool. Instant Online Mandeali Converter."
  },
  {
    name: "Manipuri, Bengali",
    code: "mni_beng",
    isTranslitarationSupported: true,
    path: "/englishtomanipuri",
    placeholder: "",
    title: "Free English to Manipuri (Bengali) Transliteration Tool | Type in Manipuri (টাইপ ইন মানিপুরি ) in 2021",
    description: "Type in Manipuri like a pro using Free English to Manipuri Translation Bobble Input tool. Instant Online Manipuri Converter"
  },
  {
    name: "Marwari (India)",
    code: "mwr_dev",
    isTranslitarationSupported: true,
    path: "/englishtomarwari-india",
    placeholder: "",
    title: "Free English to Marwari (India) Transliteration Tool | Type in Marwari (India) (मरवारी मेइन त्यपे करेइन) in 2021",
    description: "Type in Marwari (India) like a pro using Free English to Marwari (India) Translation Bobble Input tool. Instant Online Marwari (India) Converter."
  },
  {
    name: "Marwari (Pakistan)",
    code: "mwr_arab",
    isTranslitarationSupported: true,
    path: "/englishtomarwari-pakistan",
    placeholder: "",
    title: "Free English to Marwari (Pakistan) Transliteration Tool | Type in Marwari (Pakistan) (مرواری میں تیپے کریں) in 2021",
    description: "Type in Marwari (Pakistan) like a pro using Free English to Marwari (Pakistan) Translation Bobble Input tool. Instant Online Marwari (Pakistan) Converter."
  },
  {
    name: "Mewari",
    code: "mtr_dev",
    isTranslitarationSupported: true,
    path: "/englishtomewari",
    placeholder: "",
    title: "Free English to Mewari Transliteration Tool | Type in Mewari (मेवारी में त्यपे करें) in 2021",
    description: "Type in Mewari like a pro using Free English to Mewari Translation Bobble Input tool. Instant Online Mewari Converter."
  },
  {
    name: "Nimadi",
    code: "noe_dev",
    isTranslitarationSupported: true,
    path: "/englishtonimadi",
    placeholder: "",
    title: "Free English to Nimadi Transliteration Tool | Type in Nimadi (निमड़ी मैीनऽ तयपे करीीनऽ) in 2021",
    description: "Type in Nimadi like a pro using Free English to Nimadi Translation Bobble Input tool. Instant Online Nimadi Converter."
  },
  {
    name: "Northern Hindko",
    code: "hno_arab",
    isTranslitarationSupported: true,
    path: "/englishtonorthernhindko",
    placeholder: "",
    title: "Free English to Northern Hindko Transliteration Tool | Type in Northern Hindko (ہندکو مییں تیپ کرییں) in 2021",
    description: "Type in Northern Hindko like a pro using Free English to Northern Hindko Translation Bobble Input tool. Instant Online Northern Hindko Converter."
  },
  {
    name: "Pahari-Potwari",
    code: "phr_arab",
    isTranslitarationSupported: true,
    path: "/englishtopaharipotwari",
    placeholder: "",
    title: "Free English to Pahari-Potwari Transliteration Tool | Type in Pahari-Potwari (پہاڑی پوتواری میں ٹیپ کریں) in 2021",
    description: "Type in Pahari-Potwari like a pro using Free English to Pahari-Potwari Translation Bobble Input tool. Instant Online Pahari-Potwari Converter."
  },
  {
    name: "Pashto",
    code: "ps_arab",
    isTranslitarationSupported: true,
    path: "/englishtopashto",
    placeholder: "",
    title: "Free English to Pashto Transliteration Tool | Type in Pashto (پشتو مین تیپاڼي کرین) in 2021",
    description: "Type in Pashto like a pro using Free English to Pashto Translation Bobble Input tool. Instant Online Pashto Converter."
  },
  {
    name: "Punjabi, Arabic",
    code: "pa_arab",
    isTranslitarationSupported: true,
    path: "/englishtopunjabi-arabic",
    placeholder: "",
    title: "Free English to Punjabi, Arabic Transliteration Tool | Type in Punjabi, Arabic (پنجبی مین ٹیپے کرین) in 2021",
    description: "Type in Punjabi, Arabic like a pro using Free English to Punjabi, Arabic Translation Bobble Input tool. Instant Online Punjabi, Arabic Converter."
  },
  {
    name: "Rangpuri, Devanagari",
    code: "rkt_dev",
    isTranslitarationSupported: true,
    path: "/englishtorangpuri-devanagari",
    placeholder: "",
    title: "Free English to Rangpuri, Devanagari Transliteration Tool | Type in Rangpuri, Devanagari (रान्गपुरि मेइन टाइप करेइन) in 2021",
    description: "Type in Rangpuri, Devanagari like a pro using Free English to Rangpuri, Devanagari Translation Bobble Input tool. Instant Online Rangpuri, Devanagari Converter."
  },
  {
    name: "Santali, Bengali",
    code: "sat_beng",
    isTranslitarationSupported: true,
    path: "/englishtosantali",
    placeholder: "",
    title: "Free English to Santali, Bengali Transliteration Tool | Type in Santali, Bengali (সান্তালি মেইন টাইপ কারেইন) in 2021",
    description: "Type in Santali, Bengali like a pro using Free English to Santali, Bengali Translation Bobble Input tool. Instant Online Santali, Bengali Converter."
  },
  {
    name: "Sindhi, Arabic",
    code: "sd_arab",
    isTranslitarationSupported: true,
    path: "/englishtosindhi-arabic",
    placeholder: "",
    title: "Free English to Hindi Transliteration Tool | Type in Sindhi, Arabic (سندهي من تائپ کرين) in 2021",
    description: "Type in Sindhi, Arabic like a pro using Free English to Sindhi, Arabic Translation Bobble Input tool. Instant Online Sindhi, Arabic Converter."
  },
  {
    name: "Sindhi, Devanagari",
    code: "sd_dev",
    isTranslitarationSupported: true,
    path: "/englishtosindhi-devanagari",
    placeholder: "",
    title: "Free English to Sindhi, Devanagari Transliteration Tool | Type in Sindhi, Devanagari (सिंधि में टिपे करें) in 2021",
    description: "Type in Sindhi, Devanagari like a pro using Free English to Sindhi, Devanagari Translation Bobble Input tool. Instant Online Sindhi, Devanagari Converter."
  },
];