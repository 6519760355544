import { languages } from "../config/language-dropdown-countries";

export const retrieveFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data;
};

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const deleteFromLocalStorage = () => {
  localStorage.clear();
};

export const getSelectedLanguage = () => {
  let languageCode = localStorage.getItem("languageCode");
  if (languageCode == null || languageCode === undefined) {
    languageCode = languages[0].code;
    return languageCode;
  }
  return languageCode;
};
