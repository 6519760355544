import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Import style sheets
import "./assets/fontawesome-free-5.14.0-web/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import "./styles/header.scss";
import "./styles/input-tool.scss";
import "./styles/apk-listing.scss";
import "./styles/footer.scss";


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
