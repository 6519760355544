let productionHost = "https://api.inputtool.com";
let stagingHost = "https://stage-api.inputtool.com";
let localHost = "http://127.0.0.1:4000";

let host = productionHost;
if (window._env_.REACT_APP_ENVIRONMENT === "staging") host = stagingHost;
if (window._env_.REACT_APP_ENVIRONMENT === "local") host = localHost;


export default host;
