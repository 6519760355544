import React from "react";
import { apkListing } from "../config/apk-listing";
import { getTrackableDownloadLink } from "../utilities/apiUtlis";

class ApkListing extends React.Component {
  render() {
    return (
      <div className="apk-listing d-block mx-auto" id="apk-listing">
        <h4 className="header mt-4">Try out on mobile</h4>
        <div className="listing">
          {apkListing.map((apk, index) => {
            return (
              <div className="feature-card mr-3" key={index}>
                <div className="feature-card-content d-flex flex-row img-max-100">
                  <a target="_blank" href={apk.websiteUrl} rel="noreferrer">
                    <img
                      src={apk.image}
                      className="feature-card-image img-responsive"
                      alt="feature"
                    />
                  </a>
                  <div className="feature-card-text-container">
                    <a
                      target="_blank"
                      href={apk.websiteUrl}
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <p className="feature-card-text pt-1">{apk.text}</p>
                    </a>
                    <a
                      className="text-decoration-none"
                      href={getTrackableDownloadLink(apk.playstoreUrl)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="btn d-block ml-auto">Install</button>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ApkListing;
