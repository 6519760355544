/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import { languages } from "../config/language-dropdown-countries";
import getCaretCoordinates from "textarea-caret";
import { keyCodeToPreventDropdownClose, numericKeys } from "../config/config";
import { fetchSuggestions } from "../utilities/apiUtlis";
import {
  saveToLocalStorage,
  retrieveFromLocalStorage,
} from "../utilities/commonUtil";
import { Helmet } from "react-helmet";

class Editor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLanguageDropDownOpen: false,
      selectedLanguage: languages[0],
      languages: languages,
      isSuggestionsDropdownOpen: false,
      searchString: "",
      textarea: "",
      filteredLanguages: languages,
      currentWord: "",
      top: 0,
      left: 0,
      height: 0,
      textareaTop: 0,
      textareaLeft: 0,
      dropdownWord: "",
      editorSelectionStart: 0,
      editorSelectionEnd: 0,
      suggestions: [],
      suggestionsInView: [],
      maxSuggestionsInView: 6,
      selectedSuggestion: 0,
      suggestionBlock: 1,
      loadingSuggestions: false,
      innerWidth: 0,
    };
  }

  // check localstorage for selected language/ if path added directly on searchbar else route to default path
  componentDidMount() {
    let languageCode = retrieveFromLocalStorage("languageCode");
    let selectedLanguage = {};
    for (let i = 0; i < languages.length; i++) {
      if (languageCode === languages[i].code) {
        selectedLanguage = languages[i];
        // For mobile view
        this.setState({ selectedLanguage });

        return;
      }
    }

    selectedLanguage = languages[0];
    this.setState({ selectedLanguage: selectedLanguage });
  }

  setLanguage = (language) => {
    let path = language.path;
    if (window.location.pathname.includes("/editor")) {
      path = "/editor" + language.path;
    }
    window.history.replaceState({}, "Bobble Input Tools Online", path);
    saveToLocalStorage("languageCode", language.code);
    let selectedLanguage = language;
    this.setState({ selectedLanguage });   
  };

  // TODO: reset nai krna suggestions jb tk word change na ho
  getSuggestions = async () => {
    let { suggestions, maxSuggestionsInView, dropdownWord, selectedLanguage } =
      this.state;
    this.setState({ loadingSuggestions: true });
    let languageCode = selectedLanguage.code;

    // TODO: google also saves old suggesstions till the new ones are loading
    let response = await fetchSuggestions(dropdownWord, languageCode);
    if (response) {
      suggestions = response.data.data;
      suggestions.push(dropdownWord);
    } else {
      // if (dropdownWord.length > 1) suggestions.push(dropdownWord);
      // else 
      suggestions = [dropdownWord];
    }

    // append the current dropdown word to suggestions
    this.setState({
      suggestionsInView: suggestions.slice(0, maxSuggestionsInView),
      selectedSuggestion: 0,
      suggestionBlock: 1,
      suggestions,
    });
  };

  toggle = () =>
    this.setState({
      isLanguageDropDownOpen: !this.state.isLanguageDropDownOpen,
    });

  toggleSuggestionDropdown = () =>
    this.setState({
      isSuggestionsDropdownOpen: !this.state.isSuggestionsDropdownOpen,
    });

  search = (event) => {
    let { languages, searchString } = this.state;
    let filteredLanguages = languages.filter((language) =>
      language.name.toLowerCase().includes(searchString.toLowerCase())
    );
    this.setState({ filteredLanguages });
  };

  getDropDownColumnRecords = (languages) => {
    let { selectedLanguage } = this.state;
    return languages.map((language, index) => {
      return (
        <DropdownItem
          key={index}
          onClick={() => {
            this.setLanguage(language);
          }}
          className={
            selectedLanguage.name === language.name ? "selected-language" : ""
          }
        >
          {language.name}
        </DropdownItem>
      );
    });
  };

  getDropdownColumn = () => {
    let { filteredLanguages } = this.state;
    let frequentLanguages = filteredLanguages;
    let languagesPerColumn = 16;
    let noOfColumns = frequentLanguages.length / 16;

    let columns = [];
    for (let i = 0; i < noOfColumns; i++) {
      let column = (
        <div className="dropdown-column" key={i}>
          {this.getDropDownColumnRecords(
            frequentLanguages.slice(
              i * languagesPerColumn,
              (i + 1) * languagesPerColumn
            )
          )}
        </div>
      );
      columns.push(column);
    }
    return columns;
  };

  insertAtCursor = (myField, myValue, selectionStart, selectionEnd) => {
    //IE support
    if (document.selection) {
      myField.focus();
    }

    //MOZILLA and others
    else if (selectionStart || selectionStart === "0") {
      var startPos = selectionStart;
      var endPos = selectionEnd;

      myField.value =
        myField.value.substring(0, startPos) +
        myValue +
        " " +
        myField.value.substring(endPos - 1, myField.value.length);

      myField.selectionStart = startPos + myValue.length + 1;
      // -1 here
      myField.selectionEnd = endPos + myValue.length;
      this.setState({ textarea: myField.value });
    } else {
      myField.value += myValue + " ";
    }
  };

  // for keys like ctrl etc dropdown should not close niether select, for back space, delete don't do anything
  // CTRL+v it should not open dropdown and simply paste in text editor
  onKeyDown = (event) => {
    let {
      dropdownWord,
      editorSelectionStart,
      editorSelectionEnd,
      selectedSuggestion,
      suggestions,
      maxSuggestionsInView,
      suggestionBlock,
    } = this.state;
    let suggestionsInView = [];
    let selectedSuggestionIndex = 0;
    // logic in the 2 function is similar so we can make a common method with switch case later on
    // add slice for 6 elements to be shown, add selection check for max array length and min as well before inc and dec
    if (event.key === "ArrowUp") {
      if (selectedSuggestion >= 1) {
        selectedSuggestionIndex = --selectedSuggestion;
        this.setState({
          selectedSuggestion: selectedSuggestionIndex,
        });
      }

      if (
        selectedSuggestionIndex % maxSuggestionsInView ===
        maxSuggestionsInView - 1
      ) {
        --suggestionBlock;
        suggestionsInView = suggestions.slice(
          (suggestionBlock - 1) * maxSuggestionsInView,
          suggestionBlock * maxSuggestionsInView
        );
        this.setState({
          suggestionBlock,
          selectedSuggestion: selectedSuggestionIndex,
          suggestionsInView,
        });
      }

      return;
    } else if (event.key === "ArrowDown") {
      if (selectedSuggestion < suggestions.length - 1) {
        selectedSuggestionIndex = ++selectedSuggestion;
        // console.log("selectedSuggestionIndexDown", selectedSuggestionIndex);
        this.setState({
          selectedSuggestion: selectedSuggestionIndex,
        });
      }

      if (
        selectedSuggestionIndex > 0 &&
        (selectedSuggestionIndex - 1) % maxSuggestionsInView ===
        maxSuggestionsInView - 1
      ) {
        ++suggestionBlock;
        suggestionsInView = suggestions.slice(
          (suggestionBlock - 1) * maxSuggestionsInView,
          suggestionBlock * maxSuggestionsInView
        );
        this.setState({
          suggestionBlock,
          selectedSuggestion: selectedSuggestionIndex,
          suggestionsInView,
        });
      }

      return;
    }

    // check to see if keypress is backspace or delete, is yes then check if length of dropdown word is 0
    else if (
      (event.key === "Backspace" || event.key === "Delete") &&
      dropdownWord.length === 0
    ) {
      let editor = document.getElementById("demobox");
      editor.selectionStart = editorSelectionStart;
      editor.selectionEnd = editorSelectionEnd - 1;
      this.setState({
        isSuggestionsDropdownOpen: false,
        dropdownWord: "",
        selectedSuggestion: 0,
        suggestionsInView: [],
      });
      // set cusor to prev position
      document.getElementById("ita-ppe-box").style.display = "none";
      document.getElementById("demobox").focus();
    } else if (keyCodeToPreventDropdownClose.includes(event.key)) {
      return;
    }
    // Incase of numeric keys dropdownWord.length-1
    // also add a check to see if the 1st character is a number or not is yes then allow adding digits
    else if (numericKeys.includes(event.key)) {
      let editor = document.getElementById("demobox");
      let { selectedSuggestion, suggestions } = this.state;

      //TODO add the same check for UP and down keys as well
      if (suggestions.length > 0) {
        this.insertAtCursor(
          editor,
          // dropdownWord.slice(0, -1),
          suggestions[selectedSuggestion],
          editorSelectionStart,
          editorSelectionEnd
        );
      }
      this.setState({
        isSuggestionsDropdownOpen: false,
        dropdownWord: "",
        selectedSuggestion: 0,
        suggestionsInView: [],
      });

      document.getElementById("ita-ppe-box").style.display = "none";
      document.getElementById("demobox").focus();
      return;
    } else if (
      ((event.keyCode >= 65 && event.keyCode <= 90) ||
        (event.keyCode >= 48 && event.keyCode <= 57)) &&
      !event.ctrlKey
    ) {
      document.getElementById("ita-ppe-box").style.display = "block";
      this.setState({ isSuggestionsDropdownOpen: true });
    } else {
      let editor = document.getElementById("demobox");
      let { selectedSuggestion, suggestions } = this.state;
      if (suggestions.length > 0) {
        this.insertAtCursor(
          editor,
          // dropdownWord,
          suggestions[selectedSuggestion],
          editorSelectionStart,
          editorSelectionEnd
        );
      }
      this.setState({
        isSuggestionsDropdownOpen: false,
        dropdownWord: "",
        selectedSuggestion: 0,
        suggestionsInView: [],
      });

      document.getElementById("ita-ppe-box").style.display = "none";
      document.getElementById("demobox").focus();
    }
  };

  handleTextAreaChange = (e) => {
    let { selectedLanguage } = this.state;
    // console.log("Event", e);
    if (this.state.isSuggestionsDropdownOpen) {
      this.setState({ textarea: this.state.textarea });
    } else {
      // console.log("event.keyCode", e.keyCode);
      let { textarea } = this.state;

      // Suggestions index should default to 0 in dropdown text change and on dropdown open
      // check if the length of text in textarea has increased, also check that the data returned by e.nativeEvent.data is not null and " " and is not a special character
      // if the above statements match the open the suggestions dropdown else simply add the changes to textarea
      if (
        e.target.value.length > textarea.length &&
        e.nativeEvent.data !== null &&
        e.nativeEvent.data !== " " &&
        !/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”"'<>?\\]/g.test(e.nativeEvent.data) &&
        selectedLanguage.isTranslitarationSupported === true && // add a language check to make sure suggestions dropdown is never opened for the language which does not support transliteration
        window.innerWidth > 700
      ) {
        let editor = document.getElementById("demobox");
        var caret = getCaretCoordinates(e.target, e.target.selectionEnd);

        this.setState(
          {
            top: caret.top,
            left: caret.left,
            height: caret.height,
            textareaTop: caret.top + caret.height,
            textareaLeft: caret.left,
            suggestions: [],
            isSuggestionsDropdownOpen: true,
            editorSelectionStart:
              textarea.length > 0
                ? editor.selectionStart - 1
                : editor.selectionStart,
            editorSelectionEnd: editor.selectionEnd,
            dropdownWord:
              textarea.length > 0
                ? editor.value.substring(
                  editor.selectionStart,
                  editor.selectionStart - 1
                )
                : e.target.value[0],
          },
          () => {
            this.getSuggestions();
          }
        );

        document.getElementById("ita-ppe-box").style.display = "block";
        document.getElementById("currWord").focus();
      } else {
        this.setState({ textarea: e.target.value });
      }
    }
  };

  debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  getDebounedSuggestions = this.debounce(function (e) {
    this.getSuggestions();
  }, 500);

  render() {
    let {
      isLanguageDropDownOpen,
      selectedLanguage,
      selectedSuggestion,
      dropdownWord,
      searchString,
      suggestionsInView,
      maxSuggestionsInView,
      loadingSuggestions,
    } = this.state;

    return (
      <div className="editor d-block mx-auto">
        <Helmet>
          <title>{selectedLanguage.title}</title>
          <meta name="description" content={selectedLanguage.description} />
        </Helmet>
        <div className="editor-toolbar">
          <Dropdown isOpen={isLanguageDropDownOpen} toggle={this.toggle}>
            <DropdownToggle caret>{selectedLanguage.name}</DropdownToggle>
            <DropdownMenu className="dropdown-menu">
              <div className="w-25 pl-4 mt-2">
                <Input
                  className="form-control "
                  placeholder="Search"
                  name="search"
                  value={searchString}
                  onChange={(evt) => {
                    this.setState({ searchString: evt.target.value }, () => {
                      this.search();
                    });
                  }}
                />
              </div>
              <hr className="w-100" />
              <div className="row">{this.getDropdownColumn()}</div>
            </DropdownMenu>
          </Dropdown>
        </div>

        <div id="democontainer">
          <Input
            type="textarea"
            name="editor"
            className="editor-box"
            id="demobox"
            onChange={(e) => {
              this.handleTextAreaChange(e);
            }}
            placeholder={dropdownWord === "" && window.location.href.indexOf("editor") > -1 ? selectedLanguage.placeholder : ""}
            value={this.state.textarea}
          />
          <div
            id="ita-ppe-box"
            className="ita-ppe-box user-select-none"
            tabIndex="-1"
            style={{
              left: this.state.textareaLeft,
              top: this.state.textareaTop,
            }}
          >
            <div className="ita-ppe-edit user-select-none">
              <Input
                id="currWord"
                onChange={(e) => {
                  this.setState({ dropdownWord: e.target.value }, () => {
                    if (e.target.value !== "") {
                      this.getSuggestions();
                      // this.getDebounedSuggestions();
                    }
                  });
                }}
                max={5}
                onKeyUp={this.onKeyDown}
                value={dropdownWord}
              />
            </div>
            <div className="suggestions-list-container user-select-none mt-4">
              {loadingSuggestions ? (
                <div className="suggestions-list user-select-none">
                  {suggestionsInView.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          selectedSuggestion % maxSuggestionsInView === index
                            ? "suggestion-list-item user-select-none selected-suggestion"
                            : "suggestion-list-item user-select-none"
                        }
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="suggestions-list user-select-none d-flex justify-content-center">
                  <span className="opacity-4">Loading...</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="editor-footer">
        </div>
      </div>
    );
  }
}

export default Editor;
