export const apkListing = [
  {
    text: "Bobble Indic Keyboard - Stickers, Ғonts & Themes",
    image: "/images/apk-listing/bobble-keyboard.png",
    websiteUrl: "https://www.bobble.ai/en/download-latest-apk",
    playstoreUrl: "https://bnc.lt/a/key_live_ffovvX2jss5w31eny3lUrbnaaejKHhAD?~channel=website&~campaign=__UTM_CAMPAIGN__&utmCampaign=__UTM_CAMPAIGN__&utmMedium=__UTM_MEDIUM__&utmSource=__UTM_SOURCE__&utmTerm=__UTM_TERM__&utmContent=__UTM_CONTENT__&$match_duration=86400&$always_deeplink=false$desktop_url=https://play.google.com/store/apps/details?id=com.touchtalent.bobbleapp&$fallback_url=https://play.google.com/store/apps/details?id=com.touchtalent.bobbleapp",    
  },
  {
    text: "Hindi Keyboard with Hindi Stickers",
    image: "/images/apk-listing/hindi-keyboard.png",
    websiteUrl: "https://hindikeyboardapp.com/",
    playstoreUrl: "https://bnc.lt/a/key_live_ogV6RXfdwmlekz2qThnaAgaizvcK9SAQ?~channel=website&~campaign=__UTM_CAMPAIGN__&utmCampaign=__UTM_CAMPAIGN__&utmMedium=__UTM_MEDIUM__&utmSource=__UTM_SOURCE__&utmTerm=__UTM_TERM__&utmContent=__UTM_CONTENT__&$match_duration=86400&$always_deeplink=false",
  },
  {
    text: "Bangla Keyboard with Bangla Stickers",
    image: "/images/apk-listing/bangla-keyboard.png",
    websiteUrl: "https://banglakeyboardapp.com/",
    playstoreUrl: "https://bnc.lt/a/key_live_epJKW4NrJZY0GsLBPUG0lipewuing49x?~channel=website&~campaign=__UTM_CAMPAIGN__&utmCampaign=__UTM_CAMPAIGN__&utmMedium=__UTM_MEDIUM__&utmSource=__UTM_SOURCE__&utmTerm=__UTM_TERM__&utmContent=__UTM_CONTENT__&$match_duration=86400&$always_deeplink=false",
  },
  {
    text: "Marathi Keyboard with Marathi Stickers",
    image: "/images/apk-listing/marathi-keyboard.png",
    websiteUrl: "https://marathikeyboard.com/",
    playstoreUrl: "https://bnc.lt/a/key_live_dgVKZ8PvS0369s5gjFg1EakfDBcpEh6H?~channel=website&~campaign=__UTM_CAMPAIGN__&utmCampaign=__UTM_CAMPAIGN__&utmMedium=__UTM_MEDIUM__&utmSource=__UTM_SOURCE__&utmTerm=__UTM_TERM__&utmContent=__UTM_CONTENT__&$match_duration=86400&$always_deeplink=false",
  },
  {
    text: "Malayalam Keyboard with Malayali Stickers",
    image: "/images/apk-listing/malayalam-keyboard.png",
    websiteUrl: "https://malayalamkeyboard.com/",
    playstoreUrl: "https://bnc.lt/a/key_live_hfPGG1ivxFzS6LFsLglU5fioBybyZZKO?~channel=website&~campaign=__UTM_CAMPAIGN__&utmCampaign=__UTM_CAMPAIGN__&utmMedium=__UTM_MEDIUM__&utmSource=__UTM_SOURCE__&utmTerm=__UTM_TERM__&utmContent=__UTM_CONTENT__&$match_duration=86400&$always_deeplink=false",
  },
  {
    text: "Bobble Keyboard – Sinhala, Tamil, GIFs, Stickers",
    image: "/images/apk-listing/sinhala-keyboard.png",
    websiteUrl: "https://sinhalatamilkeyboard.com/",
    playstoreUrl: "https://bnc.lt/a/key_live_cmP0RZMIRXzSSbCd7NsikcneFtev8nqP?~channel=website&~campaign=__UTM_CAMPAIGN__&utmCampaign=__UTM_CAMPAIGN__&utmMedium=__UTM_MEDIUM__&utmSource=__UTM_SOURCE__&utmTerm=__UTM_TERM__&utmContent=__UTM_CONTENT__&$match_duration=86400&$always_deeplink=false",
  },
];
