import { Fragment } from "react";
import Container from "react-bootstrap/Container";

function FooterComponent(props) {
  return (
    <Fragment>
      <Container fluid style={{ backgroundColor: "#171717" }}>
        <div className="footer">
          <div className="footer-row">
            <div className="text-left product-col col-6 col-xl-3">
              <p className="text-white col-hd"> ABOUT</p>
              <a href="https://bobble.ai/home" target="_blank" rel="noreferrer">
                <p className="pr">Home</p>
              </a>

              <a
                href="https://bobble.ai/careers"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Careers</p>
              </a>

              <a
                href="https://www.bobble.ai/en/privacy"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Privacy Policy</p>
              </a>

              <a
                href="https://www.bobble.ai/en/terms"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Terms of service</p>
              </a>
            </div>

            {/* Business */}
            <div className="text-left product-col col-6 col-xl-3">
              <p className="text-white col-hd">Business</p>
              <a
                href="https://bobble.ai/en/marketing"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Marketing</p>
              </a>

              <a
                href="https://bobble.ai/licensing"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Licensing</p>
              </a>

              <a
                href="https://bobble.ai/en/programmatic-ads"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Programmatic Ads</p>
              </a>
              <a
                href="https://bobble.ai/data-intelligence"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Intelligence</p>
              </a>
            </div>
          </div>

          <div className="footer-row">
            {/* Technology */}
            <div className="text-left product-col col-6 col-xl-3">
              <p className="text-white col-hd">Technology</p>
              <a
                href="https://bobble.ai/ime-test-suite"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Ime Test Suite</p>
              </a>

              <a
                href="https://bobble.ai/bobble-head"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Bobble head creation SDK</p>
              </a>
            </div>

            {/* Content */}
            <div className="text-left product-col col-6 col-xl-3">
              <p className="text-white col-hd">Content</p>
              <a
                href="https://bobble.ai/stickerstore"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Stickers</p>
              </a>
              <a href="https://bobble.ai/gifs" target="_blank" rel="noreferrer">
                <p className="pr">GIFs</p>
              </a>
              <a
                href="https://bobble.ai/stories"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pr">Stories</p>
              </a>
            </div>
          </div>
        </div>
        <br />
        <p className="text-center text-white mt-1 follow_us_header">
          Follow us
        </p>
        <div
          className="text-center text-white footer_social_as_container pb-4"
          style={{ fontSize: "25px" }}
        >
          <span className="mx-4">
            <a
              href="https://www.youtube.com/channel/UCUCOSrk5Y3o4I_ix7AWr_OQ"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-youtube footer-icon" aria-hidden="true"></i>
            </a>
          </span>
          <span className="mx-4">
            <a
              href="https://twitter.com/BobbleKeyboard"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter footer-icon" aria-hidden="true"></i>
            </a>
          </span>
          <span className="mx-4">
            <a
              href="https://www.facebook.com/bobbleapp/"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fab fa-facebook-f footer-icon"
                aria-hidden="true"
              ></i>
            </a>
          </span>
          <span className="mx-4">
            <a
              href="https://www.instagram.com/bobblekeyboard/"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fab fa-instagram footer-icon"
                aria-hidden="true"
              ></i>
            </a>
          </span>
          <span className="mx-4">
            <a
              href="https://www.linkedin.com/company/bobble-app/"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fab fa-linkedin-in footer-icon"
                aria-hidden="true"
              ></i>
            </a>
          </span>
        </div>
      </Container>
    </Fragment>
  );
}

export default FooterComponent;
