import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import InputTool from "./pages/InputTool";
import PageNotFound from "./pages/PageNotFound";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Editor from "./components/Editor";
import { languages } from "./config/language-dropdown-countries";
import { saveToLocalStorage } from "./utilities/commonUtil";
import reactGa from "react-ga";

class EditorComponent extends React.Component {
  render() {
    return (
      <div id="main">
        <Editor />
      </div>
    );
  }
}

function App() {
  useEffect(() => {
    reactGa.initialize("UA-59933798-1");
  });

  const setPath = (languageCode) => {
    saveToLocalStorage("languageCode", languageCode);
  };

  // checks if the url matches with the pathname sets the path for the language and its language code
  const validatePath = () => {
    let pathname = window.location.pathname;
    pathname = pathname.replace("/editor", "");

    for (let i = 0; i < languages.length; i++) {
      if (languages[i].path === pathname || pathname === "/") {
        let languageCode = languages[i].code;
        if (pathname === "/") {
          window.location.pathname = languages[0].path;
          languageCode = languages[0].code;
        }

        setPath(languageCode);
        return true;
      }
    }
    return false;
  };

  return (
    <div className="App">
      <header className="App-header"></header>
      <Router>
        {!window.location.pathname.includes("/editor") ? <Header /> : null}

        {validatePath() && !window.location.pathname.includes("/editor") ? (
          <Route path="*" exact component={InputTool} />
        ) : null}

        {validatePath() && window.location.pathname.includes("/editor") ? (
          <Route exact component={EditorComponent} />
        ) : null}

        {!validatePath() ? <Route component={PageNotFound} /> : null}

        {!window.location.pathname.includes("/editor") ? <Footer /> : null}
      </Router>
    </div>
  );
}

export default App;
