// all special characters can be added  added when inside dropdown
// Numerics trigger dropdown but, when in dropdown clicking numeric closes dropdown
// keyCodes: backspace, tab, shift, ctrl, alt, pause/break, capslock, pageup, pagedown, end, home, left-arrow, up-arrow, right-arrow, down-arrow, insert, delete, num lock, scroll lock
// "Shift", TODO: add conditional logic for ~ . | ^
export const keyCodeToPreventDropdownClose = [
  // F keys
  "F1",
  "F2",
  "F3",
  "F4",
  "F5",
  "F6",
  "F7",
  "F8",
  "F9",
  "F10",
  "F11",
  "F12",
  // other Keys
  "Backspace",
  "Delete",
  "CapsLock",
  "Control",
  "Alt",
  "Insert",
  "Pause",
  "Home",
  "PageUp",
  "PageDown",
  "End",
  "Return",
  "Option",
  "ArrowUp",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
];

// numeric keyCodes, 0-9
export const numericKeys = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
